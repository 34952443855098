<template>
  <ul>
    <li class="phoneName">
      <div class="phone">13390762991</div>
      <a href="javascript:;">
        <i class="iconfont icon-shouji"></i>
        <div>电话咨询</div>
      </a>
    </li>
    <li class="scanName">
      <img src="@/assets/rightscan.png" alt="" class="scan" />
      <a href="javascript:;">
        <i class="iconfont icon-weixin"></i>
        <div>微信咨询</div></a
      >
    </li>
    <li class="emailName">
      <div class="email">admin@lingyuxx.com</div>
      <a href="javascript:;">
        <i class="iconfont icon-youxiang"></i>
        <div>邮件咨询</div>
      </a>
    </li>
    <li @click="handleScrollTop()">
      <a href="javascript:;">
        <i
          class="
            iconfont
            icon-xiangshangyuanjiantoushangjiantouxiangshangmianxing
          "
        ></i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "right-list",
  methods: {
    handleScrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
ul {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 40;
}
li {
  width: 73px;
  height: 73px;

  margin-top: 1.5px;
  position: relative;
  text-align: center;
}
.phone,
.email {
  position: absolute;
  top: 0;
  right: 67px;
  height: 100%;
  background: #4c4c4c;
  color: #fff;
  line-height: 30px;
  line-height: 74px;
  padding: 0 30px;
  display: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 20px;
}
.scan {
  position: absolute;
  right: 80px;
  top: 0;
  width: 73px;
  height: 73px;
  display: none;
}
.phoneName:hover .phone {
  display: block;
}
.emailName:hover .email {
  display: block;
}
.scanName:hover .scan {
  display: block;
}
li a {
  display: block;
  border-radius: 8px;
  padding: 10px 8px 8px;
  width: 100%;
  height: 100%;
  color: #4c4c4c;
  background-color: #ffffff;
}
li:first-child a {
  color: #ffffff;
  background-color: #4c4c4c;
}
li a div {
  font-size: 12px;
}
li a i {
  font-size: 30px;
}
li:last-child a {
  line-height: 58px;
}
li:hover a{
  color: #fff;
  background-color: #4c4c4c;
}
</style>