import Vue from 'vue';
import VueRourer from 'vue-router';

Vue.use(VueRourer);


const router = new VueRourer({
    routes: [
        { path: '/', component: () => import('@/views/index.vue'), },//首页
        {
            path: '/productcore', component: () => import('@/views/ProductCore.vue'),
            children: [
                { path: '/devicemanage', component: () => import('../components/productcore/DeviceManage.vue'), meta: { title: 'devicemanage' } },//设备管理
                { path: '/materialmanage', component: () => import('../components/productcore/MaterialManage.vue'), meta: { title: 'materialmanage' } },//物资管理
                { path: '/teachingmanage', component: () => import('../components/productcore/TeachingManage.vue'), meta: { title: 'teachingmanage' } },//教学管理
                { path: '/laboratorymanage', component: () => import('../components/productcore/LaboratoryManage.vue'), meta: { title: 'laboratorymanage' } },//实验室管理
                { path: '/datamanage', component: () => import('../components/productcore/DataManage.vue'), meta: { title: 'datamanage' } },//数据分析管理
                { path: '/performancemanage', component: () => import('../components/productcore/PerformanceManage.vue'), meta: { title: 'performancemanage' } },//绩效管理
                { path: '/manpowermanage', component: () => import('../components/productcore/ManpowerManage.vue'), meta: { title: 'manpowermanage' } },//人力资源管理
                { path: '/workmanage', component: () => import('../components/productcore/WorkManage.vue'), meta: { title: 'workmanage' } },//OA办公管理
                { path: '/purchasemanage', component: () => import('../components/productcore/PurchaseManage.vue'), meta: { title: 'purchasemanage' } },//采购管理
            ]
        },//产品中心
        {
            path: '/product', component: () => import('@/views/product.vue'),
            children: [
                { path: '/fixedassets', component: () => import('../components/product/FixedAssets.vue'), meta: { title: 'fixedassets' } },//固定资产
                { path: '/realestate', component: () => import('../components/product/RealEstate.vue'), meta: { title: 'realestate' } },//不动产
                // { path: '/jxcmanage', component: () => import('../components/product/JxcManage.vue'), meta: { title: 'jxcmanage' } },//进销存管理
                { path: '/intangibleassets', component: () => import('../components/product/IntangibleAssets.vue'), meta: { title: 'intangibleassets' } },//无形资产
                // { path: '/intelligenceinventory', component: () => import('../components/product/IntelligenceInventory.vue'), meta: { title: 'intelligenceinventory' } },//智慧盘点
                // { path: '/assetpurchase', component: () => import('../components/product/AssetPurchase.vue'), meta: { title: 'assetpurchase' } },//资产采购
            ]
        },//资产云平台
        {
            path: '/assetprogram', component: () => import('@/views/AssetProgram.vue'),
        },//资产方案中心
        {
            path: '/schemedetails', component: () => import('@/views/SchemeDetails.vue'), 
            children: [
                { path: '/fwsysolution', component: () => import('../components/schemedetails/FwsySolution.vue'), meta: { title: 'fwsysolution' } },//防伪溯源
                { path: '/clothingsolution', component: () => import('../components/schemedetails/ClothingSolution.vue'), meta: { title: 'clothingsolution' } },//服装
                { path: '/jewelrymanage', component: () => import('../components/schemedetails/JewelryManage.vue'), meta: { title: 'jewelrymanage' } },//珠宝管理
                { path: '/filemanage', component: () => import('../components/schemedetails/FileManage.vue'), meta: { title: 'filemanage' } },//档案管理
                { path: '/gunssolution', component: () => import('../components/schemedetails/GunsSolution.vue'), meta: { title: 'gunssolution' } },//枪支
                { path: '/medicinesolution', component: () => import('../components/schemedetails/MedicineSolution.vue'), meta: { title: 'medicinesolution' } },//更多
                { path: '/smokesolution', component: () => import('../components/schemedetails/SmokeSolution.vue'), meta: { title: 'smokesolution' } },//烟草
                { path: '/datasolution', component: () => import('../components/schemedetails/DataSolution.vue'), meta: { title: 'datasolution' } },//数据中心
                { path: '/containersolution', component: () => import('../components/schemedetails/ContainerSolution.vue'), meta: { title: 'containersolution' } },//集装箱
                { path: '/alcoholsolution', component: () => import('../components/schemedetails/AlcoholSolution.vue'), meta: { title: 'alcoholsolution' } },//名酒
                { path: '/buildsolution', component: () => import('../components/schemedetails/BuildSolution.vue'), meta: { title: 'buildsolution' } },//建筑
            ]
        },//资产方案中心pfd
        {
            path: '/totalsolution', component: () => import('@/views/TotalSolution.vue'),
        },//方案中心
        { path: '/softwarecustomization', component: () => import('@/views/SoftwareCustomization.vue'), },//软件定制
        { path: '/casepage', component: () => import('@/views/CasePage.vue'), },//案例
        {
            path: '/aboutus', component: () => import('@/views/AboutUs.vue'), 
            children: [
                { path: '/aboutwe', component: () => import('../components/aboutus/AboutWe.vue'), meta: { title: 'aboutwe' } },//联系我们
                { path: '/contactus', component: () => import('../components/aboutus/ContactUs.vue'), meta: { title: 'contactus' } },//公司简介
                { path: '/recruitpartners', component: () => import('../components/aboutus/RecruitPartners.vue'), meta: { title: 'recruitpartners' } },//诚招伙伴
                { path: '/freshtalent', component: () => import('../components/aboutus/FreshTalent.vue'), meta: { title: 'freshtalent' } },//招贤纳士
                { path: '/servicenetwork', component: () => import('../components/aboutus/ServiceNetwork.vue'), meta: { title: 'servicenetwork' } },//服务网络
            ]
        },//关于我们
        {
            path: '/solution', component: () => import('@/views/solution.vue'), 
            children: [
                { path: '/zhsbsolution', component: () => import('../components/totalsolution/ZhsbSolution.vue'), meta: { title: 'zhsbsolution' } },//智慧设备
                { path: '/zhsyssolution', component: () => import('../components/totalsolution/ZhsysSolution.vue'), meta: { title: 'zhsyssolution' } },//智慧实验室
                { path: '/zhccmanage', component: () => import('../components/totalsolution/ZhccManage.vue'), meta: { title: 'zhccmanage' } },//智慧仓储
                { path: '/zhxymanage', component: () => import('../components/totalsolution/ZhxyManage.vue'), meta: { title: 'zhxymanage' } },//智慧校园
                { path: '/sbywsolution', component: () => import('../components/totalsolution/SbywSolution.vue'), meta: { title: 'sbywsolution' } },//设备运维
                { path: '/zhyqsolution', component: () => import('../components/totalsolution/ZhyqSolution.vue'), meta: { title: 'zhyqsolution' } },//智慧园区
                { path: '/zhwysolution', component: () => import('../components/totalsolution/ZhwySolution.vue'), meta: { title: 'zhwysolution' } },//智慧物业
            ]
        },//解决方案pfd
     
    ]
});

export default router;