<template>
  <div class="footerBox">
    <div class="mainauto">
      <div class="footer_top">
        <div class="logoBox">
         <img src="@/assets/logo.png" alt="" />
        </div>
        <ul>
          <li><router-link to="/">首页</router-link></li>
        </ul>
         <ul>
          <li>产品中心</li>
          <li><router-link to="/devicemanage">设备管理</router-link></li>
          <li><router-link to="/materialmanage">物资管理</router-link></li>
          <li><router-link to="/teachingmanage">教学管理</router-link></li>
          <li><router-link to="/laboratorymanage">实验室管理</router-link></li>
          <li>
            <router-link to="/datamanage">数据分析管理</router-link>
          </li>
          <li><router-link to="/performancemanage">绩效管理</router-link></li>
                   <li><router-link to="/manpowermanage">人力资源管理</router-link></li>
          <li>
            <router-link to="/workmanage">OA办公管理</router-link>
          </li>
          <li><router-link to="/purchasemanage">采购管理</router-link></li>
        </ul>
        <ul>
          <li>资产云平台</li>
          <li><router-link to="/fixedassets">固定资产</router-link></li>
          <li><router-link to="/realestate">不动产</router-link></li>
    
          <li><router-link to="/intangibleassets">无形资产</router-link></li>
      <li><router-link to="/assetprogram">资产方案中心</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/totalsolution">方案中心</router-link></li>
          <li @click="goto('/totalsolution', 'zhxy')">智慧校园平台</li>
          <li @click="goto('/totalsolution', 'zhsb')">智慧设备运维平台</li>
          <li @click="goto('/totalsolution', 'zhyq')">智慧园区平台</li>
          <li @click="goto('/totalsolution', 'zhcc')">智慧仓储物资平台</li>
          <li @click="goto('/totalsolution', 'zhsys')">智慧实验室平台</li>
          <li @click="goto('/totalsolution', 'sbyw')">设备运维管控平台</li>
          <li @click="goto('/totalsolution', 'zhwy')">智慧物业平台</li>
        </ul>
        <!-- <ul>
          <li>
            <router-link to="/softwarecustomization">软件定制</router-link>
          </li>
        </ul> -->
        <ul>
          <li><router-link to="/casepage">案例</router-link></li>
        </ul>
        <ul>
          <li>关于我们</li>
          <li><router-link to="/contactus">公司简介</router-link></li>
           <li><router-link to="/recruitpartners">诚招伙伴</router-link></li>
            <li><router-link to="/freshtalent">招贤纳士</router-link></li>
          <li><router-link to="/aboutwe">联系我们</router-link></li>
           <li><router-link to="/servicenetwork">服务网络</router-link></li>
        </ul>
        <ul>
          <li>微信公众号</li>
          <li><img class="sacn" src="@/assets/sacn.jpg" alt="" /></li>
        </ul>
      </div>
      <div class="footer_bottom">
        Copyright © 2022 南京灵育信息科技有限责任公司 版权所有.<a href="https://beian.miit.gov.cn">苏ICP备20005711号-3</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-nav",
  methods: {
    goto(page, selectID) {
      console.log(page, selectID, this.$route.path);
      if (page == this.$route.path) {
        var toElement = document.getElementById(selectID);
        toElement.classList.add("fadeIn");
        console.log(toElement);
        toElement.scrollIntoView(true);
      } else {
        //否则跳转路由
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
  },
};
</script>

<style scoped>
.footerBox {
  width: 100%;
  background-color: #0d2134;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.footer_top {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 100px 0;
}

.logoBox img {
  width: 160px;
}
.logoBox .title {
  display: inline-block;
  color: #919393;
  line-height: 26px;
}
.logoBox .title div:nth-child(1) {
  font-size: 24px;
}
.logoBox .title div:nth-child(2) {
  font-size: 14px;
}
ul li {
  color: #c0c2c3;
  line-height: 30px;
  font-size: 12px;
  cursor: pointer;
}
ul li:nth-child(1) {
  color: #cfd0d1;
  font-size: 16px;
  margin-bottom: 48px;
}
.footer_bottom {
  padding: 34px 0 78px;
  width: 100%;
  font-size: 12px;
  border-top: solid 1px #676c93;
  color: #c0c2c3;
  text-align: center;
}
.sacn {
  width: 80px;
}
</style>