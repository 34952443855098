<template>
  <div class="header-nav" ref="headernav">
    <div class="headerBox">
      <div class="logoBox">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        text-color="#b5b5b5"
      >
        <el-menu-item index="1"
          ><router-link to="/">首页</router-link></el-menu-item
        >
        <el-submenu index="2">
          <template slot="title">产品中心</template>
          <router-link to="/devicemanage"
            ><el-menu-item index="2-1">设备管理</el-menu-item></router-link
          >
          <router-link to="/materialmanage"
            ><el-menu-item index="2-2">物资管理</el-menu-item></router-link
          >

          <router-link to="/teachingmanage"
            ><el-menu-item index="2-3">教学管理</el-menu-item></router-link
          >
          <router-link to="/laboratorymanage"
            ><el-menu-item index="2-4">实验室管理</el-menu-item></router-link
          >
          <router-link to="/datamanage"
            ><el-menu-item index="2-5">数据分析管理</el-menu-item></router-link
          >

          <router-link to="/performancemanage"
            ><el-menu-item index="2-6">绩效管理</el-menu-item></router-link
          >
          <router-link to="/manpowermanage"
            ><el-menu-item index="2-7">人力资源管理</el-menu-item></router-link
          >
          <router-link to="/workmanage"
            ><el-menu-item index="2-8">OA办公管理</el-menu-item></router-link
          >
          <router-link to="/purchasemanage"
            ><el-menu-item index="2-9">采购管理</el-menu-item></router-link
          >
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">资产云平台</template>
          <router-link to="/fixedassets"
            ><el-menu-item index="3-1">固定资产</el-menu-item></router-link
          >
          <router-link to="/realestate"
            ><el-menu-item index="3-2">不动产</el-menu-item></router-link
          >

          <router-link to="/intangibleassets"
            ><el-menu-item index="3-3">无形资产</el-menu-item></router-link
          >
          <router-link to="/assetprogram"
            ><el-menu-item index="3-4">资产方案中心</el-menu-item></router-link
          >
        </el-submenu>
        <el-menu-item index="4">
          <router-link to="/totalsolution">方案中心</router-link>
        </el-menu-item>
        <el-menu-item index="5"
          ><router-link to="/casepage">案例</router-link></el-menu-item
        >
        <el-submenu index="6">
          <template slot="title">
            <router-link to="/contactus">关于我们</router-link></template
          >
          <router-link to="/contactus"
            ><el-menu-item index="6-1">公司简介</el-menu-item></router-link
          >
          <router-link to="/recruitpartners"
            ><el-menu-item index="6-2">诚招伙伴</el-menu-item></router-link
          >
          <router-link to="/freshtalent"
            ><el-menu-item index="6-3">招贤纳士</el-menu-item></router-link
          >
          <router-link to="/aboutwe"
            ><el-menu-item index="6-4">联系我们</el-menu-item></router-link
          >
          <router-link to="/servicenetwork"
            ><el-menu-item index="6-5">服务网络</el-menu-item></router-link
          >
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-nav",
  data() {
    return {
      activeIndex: "0",
      index: "",
    };
  },
  watch: {
    "$route.path"(val) {
      window.scrollTo(0, 0);
      switch (val) {
        case "/":
          this.activeIndex = "1";
          break;
        case "/devicemanage":
          this.activeIndex = "2-1";
          break;
        case "/materialmanage":
          this.activeIndex = "2-2";
          break;
        case "/teachingmanage":
          this.activeIndex = "2-3";
          break;
        case "/laboratorymanage":
          this.activeIndex = "2-4";
          break;
        case "/datamanage":
          this.activeIndex = "2-5";
          break;
        case "/performancemanage":
          this.activeIndex = "2-6";
          break;
        case "/manpowermanage":
          this.activeIndex = "2-7";
          break;
        case "/workmanage":
          this.activeIndex = "2-8";
          break;
        case "/purchasemanage":
          this.activeIndex = "2-9";
          break;
        case "/fixedassets":
          this.activeIndex = "3-1";
          break;
        case "/realestate":
          this.activeIndex = "3-2";
          break;
        case "/intangibleassets":
          this.activeIndex = "3-3";
          break;
        case "/assetprogram":
          this.activeIndex = "3-4";
          break;
        case "/totalsolution":
          this.activeIndex = "4";
          break;
        case "/casepage":
          this.activeIndex = "5";
          break;
        case "/contactus":
          this.activeIndex = "6-1";
          break;
        case "/recruitpartners":
          this.activeIndex = "6-2";
          break;
        case "/freshtalent":
          this.activeIndex = "6-3";
          break;
        case "/aboutwe":
          this.activeIndex = "6-4";
          break;
        case "/servicenetwork":
          this.activeIndex = "6-5";
          break;
      }
    },
  },
};
</script>

<style scoped>
.header-nav >>> .el-menu-item,
.header-nav >>> .el-submenu__title {
  padding: 0;
}
.el-menu--horizontal > .el-menu-item a {
  color: #b5b5b5;
}
.header-nav
  >>> .el-menu--horizontal
  > .el-menu-item
  a.router-link-exact-active {
  color: #fff;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.header-nav >>> .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}
.header-nav
  >>> .el-menu--popup-bottom-start
  a.router-link-exact-active
  .el-menu--horizontal
  .el-menu
  .el-menu-item {
  color: #b5b5b5;
}
.header-nav >>> .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
.el-menu--horizontal > .el-menu-item.is-active {
  border-color: #fff;
  color: #fff;
}
.header-nav {
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100%;
  background: rgba(32, 32, 32, 0.65);
  z-index: 99;
  transition: 1s;
}
.headerBox {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.logoBox {
  color: #fff;
  min-width: 300px;
  margin-left: 20px;
}
.logoBox img {
  margin-top: 16px;
  width: 150px;
  height: 48px;
}
.logoBox .title {
  font-size: 18px;
}
.logoBox .subtitle {
  font-size: 13px;
}
.el-menu-demo {
  min-width: 1050px;
  width: 54%;
  max-width: 1300px;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu {
  background: transparent;
}
.el-menu--horizontal > .el-menu-item,
.header-nav >>> .el-menu--horizontal > .el-submenu .el-submenu__title {
  margin-right: 78px;
  height: 80px;
  line-height: 80px;
  font-size: 22px;
}
.el-menu--horizontal > .el-menu-item a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
</style>