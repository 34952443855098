<template>
  <div id="app">
    <header-nav></header-nav>

    <router-view></router-view>

    <right-list></right-list>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "./components/common/HeaderNav";
import FooterNav from "./components/common/FooterNav";
import RightList from "./components/common/RightList";
export default {
  components: { HeaderNav, FooterNav, RightList },
  name: "App",
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>